<template>
  <div class="hp-100">
    <el-container class="hp-100">
      <el-header class="header">
        <Header />
      </el-header>
      <el-container>
        <el-aside width="250px">
          <Aside />
        </el-aside>
        <el-main class="container">
          <Breadcrumb />
          <app-main />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Aside from './components/Aside'
import Header from './components/Header'
import AppMain from './components/AppMain.vue'
import Breadcrumb from './components/Breadcrumb'
export default {
  components: {
    Aside,
    Header,
    AppMain,
    Breadcrumb,
  },
}
</script>

<style lang="less" scoped>
.header {
  height: 56px !important;
  background: #1e9fff;
}
.container {
  height: calc(100vh - 56px);
  overflow: auto;
  background: #e5e5e5;
  padding: 16px !important;
}
::-webkit-scrollbar-thumb {
  background-color: grey;
}
::-webkit-scrollbar {
  width: 10px !important;
  height: 8px;
  background-color: #fff;
}
</style>
