import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuList: [1111]
  },
  mutations: {
    getMenuList(state, n) {
      state.menuList = n;
      return state.menuList
    }
  },
  actions: {
  },
  modules: {

  }
})
