import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/mian.less";
import "./assets/css/bts.less";
import "./assets/font/iconfont";
import "./assets/font/iconfont.css";
import echart from "echarts";
import VDistpicker from "v-distpicker";
import "vue-orgchart/dist/style.min.css";
//单个图片上传
import ImgBigUpload from "@/components/ImgBigUpload";
import _ from "lodash"; //引用loadsh

Vue.use(ElementUI);
Vue.component("ImgBigUpload", ImgBigUpload);

Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false;
Vue.prototype.$echarts = echart;
//loadsh
Vue.prototype._ = _;

Vue.component("v-distpicker", VDistpicker);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
