<template>
  <div class="main">
    <!-- <div class="option"><span class="iconfont jr-iconjr-icon-bell"></span></div>
    <div class="header">
      <el-avatar
        :size="40"
        src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
      ></el-avatar>
    </div>-->
    <div class="new-visualization">
      <el-button @click="visualizationClick">可视化页面</el-button>
    </div>
    <el-dropdown placement="bottom" v-if="loginInfo.roleid == 1">
      <span class="el-dropdown-link">
        <div class="option">
          <span class="iconfont jr-iconjri-icon-Vector"></span>
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="clickTo('user')"
          >用户管理</el-dropdown-item
        >
        <el-dropdown-item @click.native="clickTo('role')"
          >角色管理</el-dropdown-item
        >
        <el-dropdown-item @click.native="clickTo('dataDictionary')"
          >数据字典管理</el-dropdown-item
        >
      </el-dropdown-menu>
      <!-- <el-dropdown-menu slot="dropdown" v-else>
        <el-dropdown-item>
          管理员权限使用
        </el-dropdown-item>
      </el-dropdown-menu>-->
    </el-dropdown>
    <el-dropdown placement="bottom">
      <span class="el-dropdown-link">
        <div class="header">
          <el-avatar :size="40" icon="el-icon-user"></el-avatar>
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="clickTo('diary')"
          >操作日志</el-dropdown-item
        >
        <el-dropdown-item @click.native="clickTo('personMessage')"
          >修改信息</el-dropdown-item
        >
        <el-dropdown-item @click.native="editPassword"
          >修改密码</el-dropdown-item
        >
        <el-dropdown-item @click.native="exit">退出系统</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      @close="handleClose"
      center
    >
      <el-form :model="loginInfo" :rules="rules" ref="ruleForm">
        <el-form-item prop="password">
          <el-input
            :type="pwdType"
            placeholder="请输入密码"
            v-model="loginInfo.password"
            autocomplete="off"
            style="width:100%"
          >
            <i slot="prefix" class="jr-iconjr-icon-cipher" />
            <div slot="suffix" :class="eyeType" @click="changeType" />
          </el-input>
        </el-form-item>
        <el-form-item prop="passwords">
          <el-input
            :type="pwdType"
            placeholder="请再次输入密码"
            v-model="loginInfo.passwords"
            autocomplete="off"
          >
            <i slot="prefix" class="jr-iconjr-icon-cipher" />
            <div slot="suffix" :class="eyeType" @click="changeType" />
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入验证码" v-model="loginInfo.code">
            <i slot="prefix" class="jr-iconjr-icon-ke" />
            <div slot="suffix" @click="remindClick" class="remind">
              {{ remind }}
              <span v-if="computeTime">{{ computeTime }}秒后重发</span>
            </div>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editPasswords">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUser, getSms, editPassword } from "@/api/common.js";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginInfo.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      loginInfo: {
        password: "",
        passwords: "",
        roleid: 0
      },
      eyeType: "jr-iconjr-icon-eyes",
      pwdType: "password",
      remind: "获取验证码",
      computeTime: "",
      rules: {
        // password: [
        //   { validator: validatePass, trigger: 'blur' }
        // ],
        passwords: [{ validator: validatePass2, trigger: "blur" }]
      }
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    //可视化页面跳转
    visualizationClick() {
      this.$router.push({
        name: "Visualization"
      });
    },
    // 获取用户信息
    async getUser() {
      const res = await getUser();
      if (res.data) {
        this.loginInfo = res.data;
      }
    },
    editPassword() {
      this.dialogVisible = true;
    },
    exit() {
      window.sessionStorage.clear();
      this.$router.go("/login");
    },
    handleClose() {
      this.loginInfo.password = "";
      this.loginInfo.passwords = "";
      this.$refs.ruleForm.resetFields();
    },
    clickTo(data) {
      this.$router.push({
        name: data
      });
    },
    changeType() {
      this.pwdType = this.pwdType === "password" ? "text" : "password";
      this.eyeType =
        this.pwdType === "password"
          ? "jr-iconjr-icon-eyes"
          : "jr-iconjr-icon-eye";
    },
    async remindClick() {
      const res = await getSms({ phoneNum: this.loginInfo.adminPhone });
      this.remind = "";
      let intervalId;
      // 如果当前没有计时
      if (!this.computeTime) {
        // 启动倒计时
        this.computeTime = 60;
        intervalId = setInterval(() => {
          this.computeTime--;
          if (this.computeTime <= 0) {
            this.remind = "重新发送";
            // 停止计时
            clearInterval(intervalId);
          }
        }, 1000);
      }
    },
    async editPasswords() {
      if (this.loginInfo.password === this.loginInfo.passwords) {
        const valueData = {
          id: this.loginInfo.id,
          adminPassword: this.loginInfo.password,
          code: this.loginInfo.code
        };
        const res = await editPassword(valueData);
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.dialogVisible = false;
        } else {
          this.$message.error("修改失败");
        }
      } else {
        this.$message.error("两次密码输入不一致");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.jr-iconjri-icon-Vector {
  color: white;
  font-size: 25px;
}
.main {
  box-sizing: border-box;
  margin-right: 5%;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  position: relative;
}
.option {
  width: 56px;
  height: 56px;
  line-height: 56px;
  box-sizing: border-box;
  cursor: pointer;
  span {
    display: inline-block;
    padding-left: 15px;
  }
}
.header {
  width: 56px;
  height: 56px;
  box-sizing: border-box;
  cursor: pointer;
  .el-avatar--circle {
    margin: 7px 0 0 7px;
  }
}
.option:hover {
  border-bottom: 2px solid #a0d4fa;
}
.header:hover {
  border-bottom: 2px solid #a0d4fa;
}
.options {
  width: 100px;
  height: 110px;
  background-color: white;
  position: absolute;
  top: 56px;
  left: -20px;
  z-index: 999;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  display: none;
  p {
    font-size: 14px;
    color: #363e4d;
    text-align: center;
    // padding: 10px 0 0 10px;
    line-height: 33px;
  }
}
/deep/ .el-dropdown-menu__item {
  text-align: center;
  color: #363e4d !important;
}
a {
  color: #363e4d;
}

/deep/ .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #1e9fff;
  color: #fff !important  ;
  a {
    color: white;
  }
}

.el-form {
  background-color: #fff;
  clip-path: polygon(100% 0%, 100% 78%, 90% 100%, 0% 100%, 0% 0%);
  border-radius: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 30px;
  img {
    width: 110px;
    height: 110px;
    height: 100%;
    padding: 20px 0;
  }
  .remind {
    color: #009fe8;
  }
  .remind:hover {
    cursor: pointer;
  }
  .el-button {
    padding: 12px 20px;
  }
}
/deep/ .el-avatar--circle {
  background-color: white;
}
/deep/ .el-icon-user {
  color: grey;
  font-size: 28px !important;
  line-height: 40px !important  ;
}
</style>
