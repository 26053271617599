<template>
  <div class="image_upload">
    <el-upload
      action
      list-type="picture-card"
      :limit="1"
      class="avatar-uploader"
      :class="{ hide: hideUpload }"
      accept="image/*"
      :http-request="upload"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      multiple
      :on-exceed="handleExceed"
      :on-success="success"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import { uploadFile } from "@/api/common";
export default {
  name: "ImgBigUpload",
  props: {
    imageShow: {
      type: String,
    },
    addTitleShow: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    imageShow(val) {
      if (val !== "") {
        this.fileList = [];
        this.fileList.push({
          name: val,
          url: val,
        });
        if (this.fileList.length > 0) {
          this.hideUpload = true;
        } else {
          this.hideUpload = false;
        }
      } else {
        this.fileList = [];
        if (this.fileList.length > 0) {
          this.hideUpload = true;
        } else {
          this.hideUpload = false;
        }
      }
    },
  },
  created() {
    if (this.imageShow !== "") {
      this.fileList = [];
      this.fileList.push({
        name: this.imageShow,
        url: this.imageShow,
      });
      if (this.fileList.length > 0) {
        this.hideUpload = true;
      } else {
        this.hideUpload = false;
      }
    }
  },
  data() {
    return {
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      testList: [],
      list: [],
      hideUpload: false,
    };
  },
  methods: {
    success() {
      this.hideUpload = true;
    },
    handleExceed(files, fileList) {},
    handleRemove(file, fileList) {
      this.$emit("removeImg", file);
      this.hideUpload = false;
      console.log(fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async upload(params) {
      const form = new FormData();
      form.append("files", params.file);
      const res = await uploadFile(form);
      if (res.code === 200) {
        this.$emit("uploadOneImgShow", ...res.data.fileLook);
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-upload-list__item {
  transition: none !important;
}
.image_upload {
  .avatar-uploader .el-upload {
    border: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  ::v-deep .el-upload--picture-card {
    font-size: 28px;
    background-color: #f7f8fa;
    color: #8c939d;
    border: none;
    width: 98px;
    height: 98px;
    line-height: 98px;
    text-align: center;
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 98px;
    height: 98px;
    text-align: center;
  }
  .avatar {
    width: 98px;
    height: 98px;
    display: block;
  }
  .addinfo_title {
    font-size: 14px;
    color: #999;
  }
  .hide ::v-deep .el-upload--picture-card {
    display: none;
  }
}
</style>
