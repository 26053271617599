<template>
  <div class="header">
    <!-- EliteGo -->
    <div class="header_title">数智产业基础数据管理系统</div>
    <Options />
  </div>
</template>

<script>
import Options from "@/components/options.vue";
export default {
  components: {
    Options,
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_title {
    color: #fff;
    font-size: 18px;
  }
}
</style>
