<template>
  <div class="breadcrumb">
    <el-button type="text" icon="jr-iconjr-icon-back" />
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <template v-for="(item, i) in levelList">
        <el-breadcrumb-item
          v-if="item.meta.title"
          :key="item.path"
          class="breadcrumb-item"
          :to="getPath(item.path)"
          >{{
            $route.params.paramsName && i === 2
              ? "" + $route.params.paramsName + " " + item.meta.title
              : item.meta.title
          }}</el-breadcrumb-item
        >
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",

  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      levelList: [],
      params: {}
    };
  },

  computed: {
    getPath(path) {
      return function(path) {
        if (
          path === "/numberProject" ||
          path === "/researchReport" ||
          path === "/knowledgeData" ||
          path === "/system" ||
          path === "/exercise" ||
          path === "/paper" ||
          path === "/equity" ||
          path === "/news" ||
          path === "/standardKnowledge"
        )
          return false;

        let pathlocal = path;

        if (pathlocal.indexOf(":") !== -1 && this.$route.params) {
          const paramsIndex = pathlocal.indexOf(":");

          const paramsStr = pathlocal.substring(paramsIndex).substring(1);

          pathlocal = pathlocal.substring(0, paramsIndex);

          pathlocal = pathlocal + this.$route.params[paramsStr];

          if (path !== pathlocal) {
            return pathlocal;
          } else {
            return path;
          }
        } else {
          return path;
        }
      };
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    // 切换侧边栏展开状态
    toggleCollapse() {
      this.$emit("update:isCollapse", !this.isCollapse);
    },
    // 动态面包屑
    getBreadcrumb() {
      const matched = this.$route.matched.filter(item => item.name);

      this.levelList = matched;
    }
  }
};
</script>

<style lang="less" scoped>
.breadcrumb {
  height: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  /deep/ .jr-iconjr-icon-back {
    color: #1f212b;
    font-size: 14px;
    margin-right: 5px;
  }
  /deep/ .el-breadcrumb__inner {
    color: #1f212b !important;
  }
}
</style>
