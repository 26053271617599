import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import menuList from "./menuList";

Vue.use(VueRouter);

// 最基础的页面结构，没有权限也可以看到
const constantRoutes = [{
    path: "/login",
    component: () => import("@/layout/components/Login"),
    hidden: true
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    redirect: "/enterprise", // 重定向到消息管理页面
    component: Layout,
    children: menuList
  },
  {
    path: "/visualization",
    name: "Visualization",
    component: () => import("@/layout/components/Visualization")
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active-class",
  linkExactActiveClass: "exact-class",
  routes: constantRoutes
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (to.path !== "/login" && token !== null) {
    next();
  } else if (to.path !== "/login" && token === null) {
    next({
      path: "/login"
    });
  } else if (to.path === "/login" && token === null) {
    next();
  } else {
    next({
      path: "/"
    });
  }
  next();
});

const resolveOriginal = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return resolveOriginal.call(this, location).catch(err => err);
};

export default router;
