import { Message } from "element-ui";
import { URL } from "../../config";
import axios from "axios";
let messageInstance = null;
const resetMessage = options => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
["error", "success", "info", "warning"].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === "string") {
      options = {
        message: options
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
export const message = resetMessage;

export function MessageError(params) {
  if (!params || typeof params === "string") {
    params = {
      message: params
    };
  }

  if (!params.message) {
    params.message = "操作失败";
  }

  Message(
    Object.assign(params, {
      type: "error",
      duration: 1800,
      customClass: "max-z"
    })
  );
}

export function MessageSuccess(params) {
  if (!params || typeof params === "string") {
    params = {
      message: params
    };
  }

  if (!params.message) {
    params.message = "操作成功";
  }

  Message(
    Object.assign(params, {
      type: "success",
      // message: '操作成功',
      duration: 1600,
      customClass: "max-z"
    })
  );
}

export function MessageWaring(params) {
  if (!params || typeof params === "string") {
    params = {
      message: params
    };
  }

  if (!params.message) {
    params.message = "请输入提示内容";
  }

  Message(
    Object.assign(params, {
      type: "warning",
      duration: 1600,
      customClass: "max-z"
    })
  );
}

export function exportData(data, privateUrl, type, fn, fileName) {
  axios({
    method: "POST",
    url: `${process.env.VUE_APP_BASE_API}system${privateUrl}`,
    data,
    responseType: "arraybuffer",
    headers: {
      Authorization: sessionStorage.getItem("token")
    }
  }).then(res => {
    let blob = new Blob([res.data], {
      type
    });
    let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
    const a = document.createElement("a"); //创建a标签
    a.style.display = "none";
    a.href = href; // 指定下载'链接
    a.download = fileName;
    a.click(); //触发下载
    setTimeout(() => {
      fn();
    }, 5000);
    window.URL.revokeObjectURL(a.href); //释放URL对象
    if (document.body.contains(a)) {
      document.body.removeChild(a); //释放标签
    }
  });
}

// 单个文件下载get请求
export function downFile(data, privateUrl, type, fileName) {
  axios({
    method: "get",
    url: `${process.env.VUE_APP_BASE_API}system${privateUrl}`,
    params: data,
    responseType: "arraybuffer",
    headers: {
      Authorization: sessionStorage.getItem("token")
    }
  }).then(res => {
    let blob = new Blob([res.data], {
      type
    });
    let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
    const a = document.createElement("a"); //创建a标签
    a.style.display = "none";
    a.href = href; // 指定下载'链接
    a.download = fileName;
    a.click(); //触发下载
    window.URL.revokeObjectURL(a.href); //释放URL对象
    if (document.body.contains(a)) {
      document.body.removeChild(a); //释放标签
    }
  });
}
