<template>
  <div class="aside">
    <el-scrollbar
      wrap-class="scrollbar-wrapper"
      wrap-style="overflow-x: hidden;"
      :native="false"
      class="hp-100"
    >
      <el-menu
        background-color="#fff"
        text-color="#606167"
        active-text-color="#FFFFFF"
        :collapse="isCollapse"
        :collapse-transition="false"
        router
        unique-opened
        :default-active="activePath"
      >
        <template v-for="item in menuList">
          <!-- 一级菜单 -->
          <router-link :to="item.path" :key="item.id" v-if="!item.children">
            <el-menu-item :index="item.path + ''">
              <i :class="item.icon" />
              <span>{{ item.name }}</span>
            </el-menu-item>
          </router-link>
          <el-submenu
            v-else
            :key="item.id"
            :index="item.path + ''"
            @click.stop="handleClick(item)"
          >
            <!-- 一级菜单模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="item.icon" />
              <!-- 文本 -->
              <span>{{ item.name }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item
              v-for="subItem in item.children"
              :key="subItem.id"
              :index="subItem.path"
              @click="saveNavState(subItem.path)"
            >
              <template slot="title">
                <!-- 图标 -->
                <!--  <i :class="subItem.meta.icon" />-->
                <!-- 文本 -->
                <span>{{ subItem.name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  created() {
    this.menuList = JSON.parse(sessionStorage.getItem("getMenuList"));
  },
  data() {
    return {
      isCollapse: false,
      activePath: "", // 当前路由
      menuList: [],
      // menuList: [
      //   {
      //     id: 1,
      //     name: "首页",
      //     path: "/home",
      //     createTime: "2021-09-26T10:35:12.000+00:00",
      //     icon: "jr-iconjr-icon-home"
      //   },
      //   {
      //     id: 2,
      //     name: "数智企业",
      //     path: "/enterprise",
      //     icon: "jr-iconjr-icon-enterprise"
      //   },
      //   {
      //     id: 3,
      //     name: "人才数据",
      //     path: "/talents",
      //     icon: "jr-iconjr-icon-personnel"
      //   },
      //   {
      //     id: 4,
      //     name: "产品数据",
      //     path: "/product",
      //     icon: "jr-iconjr-icon-product"
      //   },
      //   {
      //     id: 5,
      //     name: "数智项目",
      //     path: "/numberProject",
      //     icon: "jr-iconjr-icon-intelligence",
      //     children: [
      //       {
      //         id: 6,
      //         name: "项目数据",
      //         path: "/numberProject/projectData"
      //       },
      //       {
      //         id: 7,
      //         name: "需求机构",
      //         path: "/numberProject/demandAgency"
      //       },
      //       {
      //         id: 8,
      //         name: "供给机构",
      //         path: "/numberProject/feedMechanism"
      //       }
      //     ]
      //   },
      //   {
      //     id: 9,
      //     name: "研究报告",
      //     path: "/researchReport",
      //     icon: "jr-iconjr-icon-Research",
      //     children: [
      //       {
      //         id: 10,
      //         name: "报告数据",
      //         path: "/researchReport/reportData"
      //       },
      //       {
      //         id: 11,
      //         name: "报告机构信息",
      //         path: "/researchReport/reportingInformation"
      //       },
      //       {
      //         id: 209,
      //         name: "报告图表",
      //         path: "/researchReport/reportPictures"
      //       }
      //     ]
      //   },
      //   {
      //     id: 16,
      //     name: "知识数据",
      //     path: "/knowledgeData",
      //     icon: "jr-iconjr-icon-Knowledge",
      //     children: [
      //       {
      //         id: 17,
      //         name: "领域知识",
      //         path: "/knowledgeData/field",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 18,
      //         name: "技术知识",
      //         path: "/knowledgeData/technology",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 19,
      //         name: "行业知识",
      //         path: "/knowledgeData/industry",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 20,
      //         name: "产业链知识",
      //         path: "/knowledgeData/industrialChain",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 28,
      //         name: "政府知识",
      //         path: "/knowledgeData/commercial",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 115,
      //         name: "场景知识",
      //         path: "/knowledgeData/sceneknowledge",
      //         icon: "el-icon-eleme"
      //       }
      //     ]
      //   },
      //   {
      //     id: 12,
      //     name: "政策数据",
      //     path: "/policyData",
      //     icon: "jr-iconjr-icon-policy"
      //   },
      //   {
      //     id: 13,
      //     name: "案例数据",
      //     path: "/caseData",
      //     icon: "jr-iconjr-icon-case"
      //   },
      //   {
      //     id: 14,
      //     name: "新闻资讯",
      //     path: "/news",
      //     icon: "jr-iconjr-icon-study",
      //     children: [
      //       {
      //         id: 113,
      //         name: "资讯数据",
      //         path: "/newsInformation",
      //         icon: "jr-iconjr-icon-programme"
      //       },
      //       {
      //         id: 114,
      //         name: "资讯来源",
      //         path: "/newsSource",
      //         icon: "jr-iconjr-icon-programme"
      //       }
      //     ]
      //   },
      //   {
      //     id: 15,
      //     name: "方案数据",
      //     path: "/scheme",
      //     icon: "jr-iconjr-icon-programme"
      //   },

      //   {
      //     id: 21,
      //     name: "数智活动",
      //     path: "/exercise",
      //     icon: "jr-iconjr-icon-activity",
      //     children: [
      //       {
      //         id: 22,
      //         name: "活动数据",
      //         path: "/exercise/activeData",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 23,
      //         name: "赞助方信息",
      //         path: "/exercise/sponsor",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 24,
      //         name: "主办方信息",
      //         path: "/exercise/organizer",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 25,
      //         name: "嘉宾信息",
      //         path: "/exercise/guest",
      //         icon: "el-icon-eleme"
      //       }
      //     ]
      //   },
      //   {
      //     id: 26,
      //     name: "论文数据",
      //     path: "/paper",
      //     icon: "jr-iconjr-icon-Paper",
      //     children: [
      //       {
      //         id: 106,
      //         name: "论文数据",
      //         path: "/paper/info"
      //       },
      //       {
      //         id: 107,
      //         name: "会议期刊",
      //         path: "/paper/periodical"
      //       },
      //       {
      //         id: 108,
      //         name: "论文作者",
      //         path: "/paper/author"
      //       },
      //       {
      //         id: 109,
      //         name: "论文机构",
      //         path: "/paper/organization"
      //       }
      //     ]
      //   },
      //   {
      //     id: 27,
      //     name: "招聘数据",
      //     path: "/recruit",
      //     icon: "jr-iconjr-icon-recruit"
      //   },
      //   {
      //     id: 110,
      //     name: "产权资质",
      //     path: "/equity",
      //     icon: "jr-iconjr-icon-activity",
      //     children: [
      //       {
      //         id: 111,
      //         name: "专利",
      //         path: "/equityList",
      //         icon: "el-icon-eleme"
      //       },
      //       {
      //         id: 112,
      //         name: "软件著作权",
      //         path: "/copyright",
      //         icon: "el-icon-eleme"
      //       }
      //     ]
      //   },
      //   {
      //     id: 200,
      //     name: "客户企业",
      //     path: "/customerCompany",
      //     icon: "jr-iconjr-icon-recruit"
      //   },
      //   {
      //     id: 201,
      //     name: "标准知识",
      //     path: "/standardKnowledge",
      //     icon: "jr-iconjr-icon-Paper",
      //     children: [
      //       {
      //         id: 202,
      //         name: "国家标准",
      //         path: "/standardKnowledge/governmentStandard"
      //       },
      //       {
      //         id: 203,
      //         name: "行业标准",
      //         path: "/standardKnowledge/industrialStandard"
      //       },
      //       {
      //         id: 204,
      //         name: "地方标准",
      //         path: "/standardKnowledge/localStandard"
      //       },
      //       {
      //         id: 205,
      //         name: "团体标准",
      //         path: "/standardKnowledge/groupStandards"
      //       },
      //       {
      //         id: 206,
      //         name: "企业标准",
      //         path: "/standardKnowledge/companyStandard"
      //       },
      //       {
      //         id: 207,
      //         name: "国际标准",
      //         path: "/standardKnowledge/internationalStandard"
      //       },
      //       {
      //         id: 208,
      //         name: "国外标准",
      //         path: "/standardKnowledge/foreignStandards"
      //       }
      //     ]
      //   }
      // ]
    };
  },

  methods: {
    handleClick() {},
    saveNavState() {},
  },
  mounted() {
    document.getElementsByClassName("aside")[0].style.height =
      document.body.clientHeight - 80 + "px";
  },
};
</script>

<style lang="less" scoped>
.aside {
  overflow: auto;
  /deep/ .el-scrollbar__view {
    height: 100%;
    .el-menu {
      padding-top: 6px;
    }
  }
  .el-menu-item.is-active {
    background: #1e9fff !important;
    margin: 0 6px;
    border-radius: 2px;
  }
  .el-menu-item,
  .el-submenu__title {
    i {
      padding: 0 5px;
    }
  }
  .jr-iconjr-icon-example,
  .jr-iconjr-icon-news1,
  .jr-iconjr-icon-ways {
    font-size: 16px;
  }
}
[class^="jr-iconjr-icon-"] {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.jr-iconjr-icon-product {
  font-size: 15px !important;
}
</style>
