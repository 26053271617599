<template>
  <div class="appMain">
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view> 
    </keep-alive>-->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    provide(){
      return{
        reload: this.reload
      }
    },
    data (){
      return{
        isRouterAlive: true
      }
    },
    methods: {
      reload(){
        this.isRouterAlive = false;
        this.$nextTick(function(){
          this.isRouterAlive = true;
        })
      }
    }
  }
</script>

<style>
</style>
